import { GetStaticProps } from "next";
import { getPage } from "server/siteRepo";
import Page from "./[...slugs]";

export default Page;

export const getServerSideProps: GetStaticProps = async ({
  params: { site },
}) => {
  try {
    const data = await getPage(site as string, "");

    return {
      props: {
        websiteId: data?.site?.id,
        page: data.page,
        universalData: data.site.universalData,
        universalSections: data.site.universalSections,
        defaultLocale: data.site.defaultLocale,
        pages: data.site.pages,
        colors: data.site.colors || [],
        currentLocale: data.site.currentLocale,
        trackingCodes: data.site.trackingCodes || {},
        plan: data.site.plan,
        animation: data?.site?.animation,
        customCodes: data.site.customCodes || {},
        isUnderMaintenance: data.site.isUnderMaintenance,
        maintenancePages: data.site.maintenancePages || [],
      },
    };
  } catch (error) {
    return {
      props: { error: { name: error?.name, errorCode: error?.errorCode || null } },
    };
  }
};
